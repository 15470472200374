import moment from 'moment';

//Command 정의
const CTRL_GW_ALLCONTROL = 222          //All Device Control (gradeName별로 제어 가능)
const CTRL_GW_AIRCON_MODETEMP = 223     //냉방기 모드/온도 변경
const CTRL_GW_CIRCULATOR_LEVEL = 224    //순환기 단계별 레벨 설정
const CTRL_GW_PURIFIER_LEVEL = 225      //청정기 단계별 레벨 설정
const CTRL_GW_LEDONOFF = 226            //클락 LED 전체 ON/OFF
const CTRL_GW_ALLCTRLBYKIND = 227       //디바이스 종류별로 전체제어

const DEVICE_CTRL_GATEWAY_CTRL = 150 //DEVICE_CONTROL subcmd for GATEWAY CONTROL
const DEVICE_CTRL_GATEWAY_CTRL_RESTART = 0 //앱 재시작
const DEVICE_CTRL_GATEWAY_CTRL_REBOOT = 1 //게이트웨이 리부트
const DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP = 2 //화면캡쳐
const DEVICE_CTRL_GATEWAY_CTRL_LOGCAP = 3 //로그캡쳐
const DEVICE_CTRL_GATEWAY_CTRL_DELPUSH = 4 //푸시삭제
const DEVICE_CTRL_GATEWAY_CTRL_SETARS = 5 //ARS NO설정

const DISP_AIR_ONLY = 0;
const DISP_CMONITOR_ONLY = 1;
const DISP_AIR_CMONITOR_BOTH = 2;

const URL_PORTAL2_MISEWATCH = 0;
const URL_SHELTER_MISEWATCH = 1;
const URL_SUMTUR_MISEWATCH = 2;
const URL_KT_MISEWATCH = 3;
const URL_MY_SMART_BUS = 4;
const URL_PORTAL_SMART_BUS = 5;
const URL_PORTAL_SKINNSKIN = 6;
const URL_GANGSEO_SKINNSKIN = 7;
const URL_PORTAL_BUSSTOP = 8;

const common = {
    install(Vue) {

        Vue.prototype.$common = {};

        Vue.prototype.$common.CTRL_GW_ALLCONTROL = CTRL_GW_ALLCONTROL
        Vue.prototype.$common.CTRL_GW_AIRCON_MODETEMP = CTRL_GW_AIRCON_MODETEMP
        Vue.prototype.$common.CTRL_GW_CIRCULATOR_LEVEL = CTRL_GW_CIRCULATOR_LEVEL
        Vue.prototype.$common.CTRL_GW_PURIFIER_LEVEL = CTRL_GW_PURIFIER_LEVEL
        Vue.prototype.$common.CTRL_GW_LEDONOFF = CTRL_GW_LEDONOFF
        Vue.prototype.$common.CTRL_GW_ALLCTRLBYKIND = CTRL_GW_ALLCTRLBYKIND

        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL = DEVICE_CTRL_GATEWAY_CTRL
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_RESTART = DEVICE_CTRL_GATEWAY_CTRL_RESTART
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_REBOOT = DEVICE_CTRL_GATEWAY_CTRL_REBOOT
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP = DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_LOGCAP = DEVICE_CTRL_GATEWAY_CTRL_LOGCAP
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_DELPUSH = DEVICE_CTRL_GATEWAY_CTRL_DELPUSH
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_SETARS = DEVICE_CTRL_GATEWAY_CTRL_SETARS

        Vue.prototype.$common.DISP_AIR_ONLY = DISP_AIR_ONLY
        Vue.prototype.$common.DISP_CMONITOR_ONLY = DISP_CMONITOR_ONLY
        Vue.prototype.$common.DISP_AIR_CMONITOR_BOTH = DISP_AIR_CMONITOR_BOTH

        Vue.prototype.$common.URL_PORTAL2_MISEWATCH = URL_PORTAL2_MISEWATCH
        Vue.prototype.$common.URL_SHELTER_MISEWATCH = URL_SHELTER_MISEWATCH
        Vue.prototype.$common.URL_SUMTUR_MISEWATCH = URL_SUMTUR_MISEWATCH
        Vue.prototype.$common.URL_KT_MISEWATCH = URL_KT_MISEWATCH
        Vue.prototype.$common.URL_MY_SMART_BUS = URL_MY_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SMART_BUS = URL_PORTAL_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SKINNSKIN = URL_PORTAL_SKINNSKIN
        Vue.prototype.$common.URL_GANGSEO_SKINNSKIN = URL_GANGSEO_SKINNSKIN
        Vue.prototype.$common.URL_PORTAL_BUSSTOP = URL_PORTAL_BUSSTOP

        //common sub command
        Vue.prototype.$common.MW_SubCMD_REBOOT = 0       //0
        Vue.prototype.$common.MW_SubCMD_FACTORY          //1
        Vue.prototype.$common.MW_SubCMD_SET_DIS_MODE     //2
        Vue.prototype.$common.MW_SubCMD_BLEPAIR          //3
        Vue.prototype.$common.MW_SubCMD_CAL_CO2          //4
        Vue.prototype.$common.MW_SubCMD_SET_LORA_CH      //5
        Vue.prototype.$common.MW_SubCMD_SET_GROUP_ID     //6
        Vue.prototype.$common.MW_SubCMD_RS485_TEST       //7
        Vue.prototype.$common.MW_SubCMD_CTRL_LCDBACKLIGHT//8
        Vue.prototype.$common.MW_SubCMD_CTRL_LED         //9
        Vue.prototype.$common.MW_SubCMD_FACTORYMODE      //10 // add
        Vue.prototype.$common.MW_SubCMD_SLOPE            //11 pm2p5 slope
        Vue.prototype.$common.MW_SubCMD_KFACTOR          //12 pm2p5 kfactor
    
        //clock device control subcmd define
        Vue.prototype.$common.MW_SubCMD_CTRL_CLOCK = 50  //50 for clock wifi
        Vue.prototype.$common.MW_SubCMD_CLOCK_LVL = 51        //51 (0~255)
        Vue.prototype.$common.MW_SubCMD_MOOD_LVLCOLOR = 52    //52 (0~255, red, green, blue)
        Vue.prototype.$common.MW_SubCMD_CLOCK_TIMEFORMAT = 53 //53 (0: 1:25, 01:25, 13:25)
        Vue.prototype.$common.MW_SubCMD_CLOCK_DISPMODE = 54   //54 (0: timeonly, 1:time+temp/humi, 2:time+pm, 3:temp/humi only)
        Vue.prototype.$common.MW_SubCMD_INIT_DEVICE = 55      //55 (initialize device)
        Vue.prototype.$common.MW_SubCMD_CTRL_DEVICE = 56      //56 (control device)
        Vue.prototype.$common.MW_SubCMD_INTV_DEVICE = 57      //57 (device status send interval)
        Vue.prototype.$common.MW_SubCMD_FACTORY_TEST = 58     //58 (factory test)
        Vue.prototype.$common.MW_SubCMD_PM_DATA_DEVICE = 59   //59 (send pm data)
        Vue.prototype.$common.MW_SubCMD_CV_DATA_DEVICE = 60   //60 (send co2, voc data)
        Vue.prototype.$common.MW_SubCMD_THL_DATA_DEVICE = 61   //61 (send temp, humi, level data)
        Vue.prototype.$common.MW_SubCMD_TM_DATA_DEVICE = 62    //62 (send time data --> epoch MSB-epoch-epoch-epochLSB)
        Vue.prototype.$common.MW_SubCMD_CLOCK_LEDBRTBYTIME = 63 //63 (LED bright by time --> start time-end time-bright-XX)  
        Vue.prototype.$common.MW_SubCMD_CO2VOC_LED_CTRL = 64 //64 (CO2/VOC LED On/Off, Blink Control)  
        Vue.prototype.$common.MW_SubCMD_CLOCK_TIMER = 65 //65 (Clock Up/Down Timer)  
        Vue.prototype.$common.MW_SubCMD_CLOCK_DOT_COLORMODE = 66 //66 dot led on/off when normal(white) status
        Vue.prototype.$common.MW_SubCMD_INIT_DEVICE_BYGW = 67 //67 r4471 device init by clock pressing button 8 times.
        Vue.prototype.$common.MW_SubCMD_CLOCK_STATUS = 68 //68 clock status
        Vue.prototype.$common.MW_SubCMD_TIME_INTERVAL = 69 //69 POSTIN, GETOUT등 Interval을 분단위로 설정
        Vue.prototype.$common.MW_SubCMD_VENTIL_ALARM = 70 //70 vetilation alarm on/off from seoul
        
        Vue.prototype.$common.isMobile = function () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
                //return true;
            }
        }

        Vue.prototype.$common.dispMode = function (authInfo) {
            if(authInfo.option) {
                return authInfo.option & 0xf;
            }
            return DISP_AIR_ONLY;
        }

        Vue.prototype.$common.setDispMode = function (option, dispMode) {
            return (option & 0xfffffff0) | dispMode;
        }
        
        Vue.prototype.$common.getUrl = function () {
            let url = window.location.toString();
            let ret = {url: 0, banner: "", title: "" };

            if(url.includes("shelter.misewatch.com")) {
                ret.url = URL_SHELTER_MISEWATCH;
                ret.banner = 'shelter';
                ret.title = '미세와치';
            } else if(url.includes("sumtur.misewatch.com")) {
                ret.url = URL_SUMTUR_MISEWATCH;
                ret.banner = 'sumtur';
                ret.title = '숨터';
            } else if(url.includes("my.smart-bus.co.kr")) {
                ret.url = URL_MY_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.smart-bus.co.kr")) {
                ret.url = URL_PORTAL_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.skinnskin.co.kr")) {
                ret.url = URL_PORTAL_SKINNSKIN;
                ret.banner = 'skinnskin';
                ret.title = '에어클린시스템즈';
            } else if(url.includes("gangseo.skinnskin.co.kr")) {
                ret.url = URL_GANGSEO_SKINNSKIN;
                ret.banner = 'gangseo';
                ret.title = '강서구청';
            } else if(url.includes("kt.misewatch.com")) {
                ret.url = URL_KT_MISEWATCH;
                ret.banner = 'kt';
                ret.title = '미세와치';
            } else if(url.includes("portal.busstop.co.kr")) {
                ret.url = URL_PORTAL_BUSSTOP;
                ret.banner = 'hanyang';
                ret.title = '스마트쉘터';
            } else {
                ret.url = URL_PORTAL2_MISEWATCH;
                ret.banner = 'school';
                ret.title = '미세와치';
            }

            return ret;
        }
        
        Vue.prototype.$common.isShelter = function () {
            let url = this.getUrl().url;
            return (url == URL_MY_SMART_BUS || url == URL_PORTAL_SMART_BUS || url == URL_SHELTER_MISEWATCH || url == URL_PORTAL_BUSSTOP);
            //for test
            //return true;
        }

        //전문통계에서 10분자료 추출이 가능한지 체크.
        //현재는 스킨앤스킨만 지원하기로 한다.
        Vue.prototype.$common.isSupport10MinData = function () {
            let url = this.getUrl().url;
            return (url == URL_PORTAL_SKINNSKIN);
            //for test
            //return true;
        }

        Vue.prototype.$common.isAirKoreaDevice = function (mac) {
            if(!isNaN(Number(mac.substring(1)))==true) {
                return true;
            }

            return false;
        }
    }
}

export default common;