const VOC_VBAD_START_VAL = process.env.VUE_APP_VOC_VBAD_START_VAL //220121
const VOC_INDICATION = process.env.VUE_APP_VOC_INDICATION //220121

//------ 공기질기준치------------
const PM25_VBAD_START = 76
const PM25_BAD_START = 36
const PM25_NORMAL_START = 16
const PM10_VBAD_START = 151
const PM10_BAD_START = 51
const PM10_NORMAL_START = 31
const CO2_VBAD_START = 2001
const CO2_BAD_START = 1001
const CO2_NORMAL_START = 701
//const TVOC_VBAD_START = 5001
const TVOC_VBAD_START = VOC_VBAD_START_VAL
const TVOC_BAD_START = 661
const TVOC_NORMAL_START = 221
//------ 공기질기준치------------

const table = {
    install(Vue) {

        Vue.prototype.$table = {};

        /**
         * 초미세먼지 pm25
         */
        Vue.prototype.$table.pm25 = function (data) {

            //211103
            if(data == -1 || data == 0) {
                return '0';
            }

            if (data >= PM25_VBAD_START) {
                data = '4';
            } else if (data >= PM25_BAD_START) {
                data = '3';
            } else if (data >= PM25_NORMAL_START) {
                data = '2';
            } else {
                data = '1';
            }
            return data;
        }

        /**
         * 미세먼지 pm10
         */
        Vue.prototype.$table.pm10 = function (data) {

            //211103
            if(data == -1 || data == 0) {
                return '0';
            }

            if (data >= PM10_VBAD_START) {
                data = '4';
            } else if (data >= PM10_BAD_START) {
                data = '3';
            } else if (data >= PM10_NORMAL_START) {
                data = '2';
            } else {
                data = '1';
            }
            return data;
        }

        /**
         * 이산화탄소 CO2
         */
        Vue.prototype.$table.co2 = function (data) {

            //211103
            if(data == -1) {
                return '0';
            }

            if (data >= CO2_VBAD_START) {
                data = '4';
            } else if (data >= CO2_BAD_START) {
                data = '3';
            } else if (data >= CO2_NORMAL_START) {
                data = '2';
            }else {
                data = '1';
            }
       
            return data;
        }

        /**
         * 유기화합물 VOC
         */
        Vue.prototype.$table.voc = function (data) {
            //211103
            if(data == -1) {
                return '0';
            }
            
            if (data >= TVOC_VBAD_START) {
                data = '4';
            } else if (data >= TVOC_BAD_START) {
                data = '3';
            } else if (data >= TVOC_NORMAL_START) {
                data = '2';
            } else {
                data = '1';
            }

            return data;
        }

        /**
         * 환기지수: 서울시
         */
        Vue.prototype.$table.vent = function (data) {
            if(data == -1) {
                return '0';
            }

            if (data > 1000) {
                data = '4';
            } else if (data > 850) {
                data = '3';
            } else if (data > 800) {
                data = '2';
            } else {
                data = '1';
            }

            return data;
        }

        //211014
        Vue.prototype.$table.so2 = function (data) {
            //console.log("data="+data+" status:"+String(data>>24 & 0xff));
            return String(data>>24 & 0xff);
        }
        Vue.prototype.$table.no2 = function (data) {
            return String(data>>16 & 0xff);
        }
        Vue.prototype.$table.co = function (data) {
            return String(data>>8 & 0xff);
        }
        Vue.prototype.$table.o3 = function (data) {
            return String(data & 0xff);
        }

        /**
        * 등급
        * 
        *     좋음  : 1
        *     보통  : 2
        *     나쁨  : 3
        *     아주나쁨  : 4
        * 
        *     @param {int} data : 미세먼지 값
        */
        Vue.prototype.$table.text = function (data) {
            if (data == '1') {
                return '좋음'
            } else if (data == '2') {
                return '보통'
            } else if (data == '3') {
                return '나쁨'
            } else {
                if(data == 0) { //211103
                    return '-';
                }
                return '매우나쁨'
            }
        }
        
        Vue.prototype.$table.akindex = function(type, value, level){
            let I_lo_good = level[0], I_hi_good = level[1];
            let I_lo_normal = Number(level[1])+1, I_hi_normal = level[2];
            let I_lo_bad = Number(level[2])+1, I_hi_bad = level[3];
            let I_lo_vbad = Number(level[3])+1, I_hi_vbad = level[4];

            let BP_lo=0, BP_hi=0, I_hi=0, I_lo=0;
            
            let s0=0, s1=0, s2=0, s3=0, add = 1;
            if(type == "pm10") {
                s0 = 30, s1 = 80, s2 = 150, s3 = 600;
            } else if(type == "pm25") {
                s0 = 15, s1 = 35, s2 = 75, s3 = 500;
            } else if(type == "so2") {
                s0 = 0.02, s1 = 0.05, s2 = 0.15, s3 = 1; add = 0.001;
            } else if(type == "co") {
                s0 = 2, s1 = 9, s2 = 15, s3 = 50; add = 0.1;
            } else if(type == "o3") {
                s0 = 0.03, s1 = 0.09, s2 = 0.15, s3 = 0.6; add = 0.001;
            } else if(type == "no2") {
                s0 = 0.03, s1 = 0.06, s2 = 0.2, s3 = 2; add = 0.001;
            } else {
                return 0;
            }

            if (value <= s0) {
                BP_lo = 0; BP_hi = s0; I_hi = I_hi_good; I_lo = I_lo_good;
            } else if (value > s0 && value <= s1) {
                BP_lo = Number(s0)+add; BP_hi = s1; I_hi = I_hi_normal; I_lo = I_lo_normal;
            } else if (value > s1 && value <= s2) {
                BP_lo = Number(s1)+add; BP_hi = s2; I_hi = I_hi_bad; I_lo = I_lo_bad;
            } else {
                BP_lo = Number(s2)+add; BP_hi = s3; I_hi = I_hi_vbad; I_lo = I_lo_vbad;
            }

            let index = ((I_hi-I_lo) / (BP_hi-BP_lo)) * (value - BP_lo) + I_lo;

            //console.log("type="+type+" value="+value+" BP_lo="+BP_lo+" BP_hi="+BP_hi+" I_lo="+I_lo+" I_hi="+I_hi+" index="+index)

            return index;
        }

        Vue.prototype.$table.akstatus = function(mwdDevice){
            let ret = {status:'0', statusText:'정보없음'};

            //=====================================
            // 220420
            if(mwdDevice==null || (mwdDevice.pm10 == 0 && mwdDevice.pm25 == 0)) {
                //not measured status
                ret.status = '0';
                ret.statusText = '-';

                ret.causepm10 = false;
                ret.causepm25 = false;
                ret.causeco2 = false;
                ret.causevoc = false;

                ret.out = '';
                return ret;
            }
            //=====================================
            let I_level = [0,50,100,250,500];

            let I_lo_good = I_level[0], I_hi_good = I_level[1];
            let I_lo_normal = Number(I_level[1]), I_hi_normal = I_level[2];
            let I_lo_bad = Number(I_level[2]), I_hi_bad = I_level[3];
            let I_lo_vbad = Number(I_level[3]), I_hi_vbad = I_level[4];

            /* pm10 */
            let pm10 = mwdDevice.pm10;
            if(pm10 < 0) {
                return ret;
            }
            let I_pm10 = this.akindex("pm10", pm10, I_level);

            /* pm25 */
            let pm25 = mwdDevice.pm25;
            if(pm25 < 0) {
                return ret;
            }
            let I_pm25 = this.akindex("pm25", pm25, I_level);

            let I_max;
            let I;
            let aboveBadCount = 0;
            if(mwdDevice.akConnected > 0) {
                /* so2 */
                let so2 = mwdDevice.so2;
                if(so2 < 0) {
                    return ret;
                }
                let I_so2 = this.akindex("so2", so2 / 1000, I_level);
                
                /* co */
                let co = mwdDevice.co;
                if(co < 0) {
                    return ret;
                }
                let I_co = this.akindex("co", co / 1000, I_level);

                /* o3 */
                let o3 = mwdDevice.o3;
                if(o3 < 0) {
                    return ret;
                }
                let I_o3 = this.akindex("o3", o3 / 1000, I_level);

                /* no2 */
                let no2 = mwdDevice.no2;
                if(no2 < 0) {
                    return ret;
                }
                let I_no2 = this.akindex("no2", no2 / 1000, I_level);

                aboveBadCount = 0;
                if(I_pm10 > I_hi_normal) aboveBadCount++;
                if(I_pm25 > I_hi_normal) aboveBadCount++;
                if(I_so2 > I_hi_normal) aboveBadCount++;
                if(I_co > I_hi_normal) aboveBadCount++;
                if(I_o3 > I_hi_normal) aboveBadCount++;
                if(I_no2 > I_hi_normal) aboveBadCount++;

                I = [I_pm10, I_pm25, I_so2, I_co, I_o3, I_no2];
                I_max = Math.max.apply(null, I);
            } else {
                aboveBadCount = 0;
                if(I_pm10 > I_hi_normal) aboveBadCount++;
                if(I_pm25 > I_hi_normal) aboveBadCount++;

                I = [I_pm10, I_pm25];
                I_max = Math.max.apply(null, I);
            }
            if(aboveBadCount >= 3) I_max = I_max + 75; 
            else if(aboveBadCount >= 2) I_max = I_max + 50; 

            if(I_max > I_lo_good && I_max <= I_hi_good) {
                ret.status = '1';
                ret.statusText = '좋음';
            } else if(I_max > I_lo_normal && I_max <= I_hi_normal) {
                ret.status = '2';
                ret.statusText = '보통';
            } else if(I_max > I_lo_bad && I_max <= I_hi_bad) {
                ret.status = '3';
                ret.statusText = '나쁨';
            } else {
                ret.status = '4';
                ret.statusText = '매우 나쁨';
            }

            //console.log("I="+JSON.stringify(I)+" I_max="+I_max+" aboveBadCount="+aboveBadCount+" ret="+JSON.stringify(ret));

            return ret;
        }

        Vue.prototype.$table.value = function(type, mwdDevice){
            let ret = {value:'정보없음', error:1};
    
            if(mwdDevice == null) {
                return ret;
            }
    
            if(type == 'pm10') {
                if(mwdDevice.pm10 < 0) {
                  if(Object.keys(mwdDevice).includes("pm10Flag"))
                    ret.value = mwdDevice.pm10Flag;
                } else {
                  ret.value = mwdDevice.pm10;
                  ret.error = 0;
                }
            } else if(type == 'pm25') {
                if(mwdDevice.pm25 < 0) {
                  if(Object.keys(mwdDevice).includes("pm25Flag"))
                    ret.value = mwdDevice.pm25Flag;
                } else {
                  ret.value = mwdDevice.pm25;
                  ret.error = 0;  
                }
            } else if(type == 'so2') {
                if(mwdDevice.so2 < 0) {
                  if(Object.keys(mwdDevice).includes("so2Flag"))
                    ret.value = mwdDevice.so2Flag;  
                } else {
                  ret.value = mwdDevice.so2 / 1000;
                  ret.error = 0;   
                }
            } else if(type == 'co') {
                if(mwdDevice.co < 0) {
                  if(Object.keys(mwdDevice).includes("coFlag"))
                    ret.value = mwdDevice.coFlag;     
                } else {
                  ret.value = mwdDevice.co / 1000;
                  ret.error= 0;
                }
            } else if(type == 'o3') {
                if(mwdDevice.o3 < 0) {
                  if(Object.keys(mwdDevice).includes("o3Flag"))
                    ret.value = mwdDevice.o3Flag;     
                } else {
                  ret.value = mwdDevice.o3 / 1000;
                  ret.error = 0;
                }
            } else if(type == 'no2') {
                if(mwdDevice.no2 < 0) {
                  if(Object.keys(mwdDevice).includes("no2Flag"))
                    ret.value = mwdDevice.no2Flag;     
                } else {
                  ret.value = mwdDevice.no2 / 1000;
                  ret.error = 0;
                }
            } else if(type == 'co2') {
                if(mwdDevice.co2 >= 0) {
                  ret.value = mwdDevice.co2;
                  ret.error = 0;  
                }
            } else if(type == 'voc') {
                if(mwdDevice.voc >= 0) {
                  ret.value = mwdDevice.voc;
                  ret.error = 0;  
                }
            } else if(type == 'temp') {
                ret.value = mwdDevice.temp;
                ret.error = 0;
            } else if(type == 'humi') {
                if(mwdDevice.humm >= 0) {
                  ret.value = mwdDevice.humm;
                  ret.error = 0;  
                }
            }
            
            return ret;
        }

        /*
        이노디지털이 제공한 공식으로 getStatus
        기존 메소드에서 변경하기 쉽도록, 해당 메소드에 mwdDevice를 넣으면 적용되도록 작성함.

        - 보통 , 나쁨 , 아주나쁨이 하나라도 존재한다면 해당 Status로 표현
        - 위의 상태가 모두 없을 경우 좋음으로 표현
        */
        Vue.prototype.$table.status = function(mwdDevice){
            let ret = new Object();

            /*
            //<< ikkkim add
            let dev = mwdDevice.mwdMac;
            //console.log("dev="+dev);

            if(typeof dev !== 'undefined') {
                //console.log("dev="+dev);
                if(dev.substring(0, 1) === 'w'){
                    ret.status = '7';
                    ret.statusText = '';
                    return ret;
                }
            }
            //>>
            */

            //211019
            if(mwdDevice!=null && mwdDevice.mwdType === "MWW") {
                let bat = mwdDevice.bat;

                if(bat > 80) ret.status = 1; //good    
                else if(bat > 60) ret.status = 2; //normal    
                else if(bat > 40) ret.status = 3; //bad    
                else ret.status = 4; //vbad    
        
                //ret.status = '7';
                ret.statusText = '';
                ret.statusLongText = '';
                ret.out = '';

                return ret;
            }

            //=====================================
            // 220420
            if(mwdDevice==null || (mwdDevice.pm10 == 0 && mwdDevice.pm25 == 0) || (mwdDevice.temp == 0 && mwdDevice.humm == 0)) {
                //not measured status
                ret.status = '0';
                //ret.statusText = '-';
                //ret.statusLongText = '-';
                ret.statusText = '';
                ret.statusLongText = '';

                ret.causepm10 = false;
                ret.causepm25 = false;
                ret.causeco2 = false;
                ret.causevoc = false;

                ret.out = '';
                return ret;
            }
            //=====================================

            let pm10 = mwdDevice.pm10;
            if (pm10 >= PM10_VBAD_START) {
                pm10 = '4';
            } else if (pm10 >= PM10_BAD_START) {
                pm10 = '3';
            } else if (pm10 >= PM10_NORMAL_START) {
                pm10 = '2';
            } else {
                pm10 = '1';
                if(pm10 < 0) pm10 = '0';
            }

            let pm25 = mwdDevice.pm25;
            if (pm25 >= PM25_VBAD_START) {
                pm25 = '4';
            } else if (pm25 >= PM25_BAD_START) {
                pm25 = '3';
            } else if (pm25 >= PM25_NORMAL_START) {
                pm25 = '2';
            } else {
                pm25 = '1';
                if(pm25 < 0) pm25 = '0';
            }

            let co2 = mwdDevice.co2;
            if (co2 >= CO2_VBAD_START) {
                co2 = '4';
            } else if (co2 >= CO2_BAD_START) {
                co2 = '3';
            } else if (co2 >= CO2_NORMAL_START) {
                co2 = '2';
            } else {
                co2 = '1';
                if(co2 < 0) co2 = '0';
            }

            //220121
            let voc = mwdDevice.voc;
            if(VOC_INDICATION == 0) {
                //always good. it means voc is don't care for total status
                if(voc < 0) voc = '0';
                else voc = '1';
            } else {

                if (voc >= TVOC_VBAD_START) {
                    voc = '4';
                } else if (voc >= TVOC_BAD_START) {
                    voc = '3';
                } else if (voc >= TVOC_NORMAL_START) {
                    voc = '2';
                } else {
                    voc = '1';
                    if(voc < 0) voc = '0';
                }
            }

            //station의 경우 미세먼지, 초미세먼지만 체크하자.
            //나쁨, 매우나쁨인경우에만 Cause를 표시한다.
            if(mwdDevice.mwdType === "MWS") {
                if(pm10 === '4' || pm25 === '4'){
                    ret.status = '4';
                    ret.statusText = '매우 나쁨';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                }else if(pm10 === '3' || pm25 === '3'){
                    ret.status = '3';
                    ret.statusText = '나쁨';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                }else if(pm10 === '2' || pm25 === '2'){
                    ret.status = '2';
                    ret.statusText = '보통';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                }else{
                    if(pm10 === '0' && pm25 === '0') {
                        ret.status = '0';
                        ret.statusText = '정보없음';
                    } else {
                        ret.status = '1';
                        ret.statusText = '좋음';
                    }

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                }
            } else { 
                if(pm10 === '4' || pm25 === '4' || co2 === '4' || voc === '4'){
                    ret.status = '4';
                    ret.statusText = '매우 나쁨';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                    ret.causeco2 = (co2 >= '3') ? true : false;
                    ret.causevoc = (voc >= '3') ? true : false;
                }else if(pm10 === '3' || pm25 === '3' || co2 === '3' || voc === '3'){
                    ret.status = '3';
                    ret.statusText = '나쁨';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                    ret.causeco2 = (co2 >= '3') ? true : false;
                    ret.causevoc = (voc >= '3') ? true : false;
                }else if(pm10 === '2' || pm25 === '2' || co2 === '2' || voc === '2'){
                    ret.status = '2';
                    ret.statusText = '보통';

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                    ret.causeco2 = (co2 >= '3') ? true : false;
                    ret.causevoc = (voc >= '3') ? true : false;
                }else{
                    if(pm10 === '0' && pm25 === '0' && co2 === '0' && voc === '0') {
                        ret.status = '0';
                        ret.statusText = '정보없음';
                    } else {
                        ret.status = '1';
                        ret.statusText = '좋음';
                    }

                    ret.causepm10 = (pm10 >= '3') ? true : false;
                    ret.causepm25 = (pm25 >= '3') ? true : false;
                    ret.causeco2 = (co2 >= '3') ? true : false;
                    ret.causevoc = (voc >= '3') ? true : false;
                }
            }

            //220225
            ret.out = '';
            if(mwdDevice.mwdType === "MWC") {
                //let out = (mwdDevice.bat >> 12) & 0xf;
                let out = (mwdDevice.outStatus);

                if(out == 1) ret.out = '1'; //good MW_LED_GOOD_FADEIN   
                else if(out == 4) ret.out = '2'; //normal MW_LED_NORMAL_FADEIN   
                else if(out == 7) ret.out = '3'; //bad MW_LED_BAD_FADEIN   
                else if(out == 10) ret.out = '4'; //vbad MW_LED_VBAD_FADEIN   
            }

            let t = [];
            if(ret.causepm10 || ret.causepm25) t.push('미세먼지');
            if(ret.causeco2) t.push('CO2');
            if(ret.causevoc) t.push('TVOC');
            if(t.length > 0) {
                ret.statusLongText = '';
                for(let i=0; i<t.length; i++) {
                    ret.statusLongText += t[i];
                    if(i < t.length-1) {
                        ret.statusLongText += ',';
                    }
                }
                ret.statusLongText += ' 나쁨이상';
            } else {
                ret.statusLongText = '좋음';
            }
            //Vue.prototype.$table.test();
            //console.log("status="+JSON.stringify(ret));
            
            return ret;
        }

        Vue.prototype.$table.pmstatus = function(mwdDevice){
            let ret = new Object();
            
            //211019
            if(mwdDevice!=null && mwdDevice.mwdType === "MWW") {
                let bat = mwdDevice.bat;

                if(bat > 80) ret.status = 1; //good    
                else if(bat > 60) ret.status = 2; //normal    
                else if(bat > 40) ret.status = 3; //bad    
                else ret.status = 4; //vbad    
        
                //ret.status = '7';
                ret.statusText = '';
                ret.out = '';

                return ret;
            }

            //=====================================
            // 220420
            if(mwdDevice==null || (mwdDevice.pm10 == 0 && mwdDevice.pm25 == 0)) {
                //not measured status
                ret.status = '0';
                ret.statusText = '-';

                //ret.causepm10 = false;
                //ret.causepm25 = false;
                //ret.causeco2 = false;
                //ret.causevoc = false;

                ret.out = '';
                return ret;
            }
            //=====================================

            let pm10 = mwdDevice.pm10;
            if (pm10 >= PM10_VBAD_START) {
                pm10 = '4';
            } else if (pm10 >= PM10_BAD_START) {
                pm10 = '3';
            } else if (pm10 >= PM10_NORMAL_START) {
                pm10 = '2';
            } else {
                pm10 = '1';
                if(pm10 < 0) pm10 = '0';
            }

            let pm25 = mwdDevice.pm25;
            if (pm25 >= PM25_VBAD_START) {
                pm25 = '4';
            } else if (pm25 >= PM25_BAD_START) {
                pm25 = '3';
            } else if (pm25 >= PM25_NORMAL_START) {
                pm25 = '2';
            } else {
                pm25 = '1';
                if(pm25 < 0) pm25 = '0';
            }

            let co2 = mwdDevice.co2;
            if (co2 >= CO2_VBAD_START) {
                co2 = '4';
            } else if (co2 >= CO2_BAD_START) {
                co2 = '3';
            } else if (co2 >= CO2_NORMAL_START) {
                co2 = '2';
            } else {
                co2 = '1';
                if(co2 < 0) co2 = '0';
            }

            //220121
            let voc = mwdDevice.voc;
            if(VOC_INDICATION == 0) {
                //always good. it means voc is don't care for total status
                if(voc < 0) voc = '0';
                else voc = '1';
            } else {

                if (voc >= TVOC_VBAD_START) {
                    voc = '4';
                } else if (voc >= TVOC_BAD_START) {
                    voc = '3';
                } else if (voc >= TVOC_NORMAL_START) {
                    voc = '2';
                } else {
                    voc = '1';
                    if(voc < 0) voc = '0';
                }
            }

            //미세먼지, 초미세먼지를 체크
            if(pm10 === '4' || pm25 === '4'){
                ret.status = '4';
                ret.statusText = '매우 나쁨';

                //ret.causepm10 = (pm10 === '4') ? true : false;
                //ret.causepm25 = (pm25 === '4') ? true : false;
            }else if(pm10 === '3' || pm25 === '3'){
                ret.status = '3';
                ret.statusText = '나쁨';

                //ret.causepm10 = (pm10 === '3') ? true : false;
                //ret.causepm25 = (pm25 === '3') ? true : false;
            }else if(pm10 === '2' || pm25 === '2'){
                ret.status = '2';
                ret.statusText = '보통';

                //ret.causepm10 = (pm10 === '2') ? true : false;
                //ret.causepm25 = (pm25 === '2') ? true : false;
            }else{
                if(pm10 === '0' && pm25 === '0') {
                    ret.status = '0';
                    ret.statusText = '정보없음';
                } else {
                    ret.status = '1';
                    ret.statusText = '좋음';
                }

                //ret.causepm10 = false;
                //ret.causepm25 = false;
            }

            //co2, voc cause
            /*
            if(co2 === '4' || voc === '4'){
                ret.causeco2 = (co2 === '4') ? true : false;
                ret.causevoc = (voc === '4') ? true : false;
            }else if(co2 === '3' || voc === '3'){
                ret.causeco2 = (co2 === '3') ? true : false;
                ret.causevoc = (voc === '3') ? true : false;
            }else if(co2 === '2' || voc === '2'){
                ret.causeco2 = (co2 === '2') ? true : false;
                ret.causevoc = (voc === '2') ? true : false;
            }else{
                ret.causeco2 = false;
                ret.causevoc = false;
            }
            */

            //220225
            ret.out = '';
            if(mwdDevice.mwdType === "MWC") {
                //let out = (mwdDevice.bat >> 12) & 0xf;
                let out = (mwdDevice.outStatus);

                if(out == 1) ret.out = '1'; //good MW_LED_GOOD_FADEIN   
                else if(out == 4) ret.out = '2'; //normal MW_LED_NORMAL_FADEIN   
                else if(out == 7) ret.out = '3'; //bad MW_LED_BAD_FADEIN   
                else if(out == 10) ret.out = '4'; //vbad MW_LED_VBAD_FADEIN   
            }

            //Vue.prototype.$table.test();
            
            return ret;
        }

        Vue.prototype.$table.staticstatus = function(elm){
            let ret = new Object();

            if(elm == null || (elm.pm10 == 0 && elm.pm25 == 0)) {
                //not measured status
                ret.status = '0';
                ret.statusText = '-';

                ret.causepm10 = false;
                ret.causepm25 = false;
                ret.causeco2 = false;
                ret.causevoc = false;

                ret.out = '';
                return ret;
            }

            //pm10
            let pm10 = elm.pm10;
            if (pm10 >= PM10_VBAD_START) {
                pm10 = '4';
            } else if (pm10 >= PM10_BAD_START) {
                pm10 = '3';
            } else if (pm10 >= PM10_NORMAL_START) {
                pm10 = '2';
            } else {
                pm10 = '1';
                if(pm10 < 0) pm10 = '0';
            }

            //pm25
            let pm25 = elm.pm25;
            if (pm25 >= PM25_VBAD_START) {
                pm25 = '4';
            } else if (pm25 >= PM25_BAD_START) {
                pm25 = '3';
            } else if (pm25 >= PM25_NORMAL_START) {
                pm25 = '2';
            } else {
                pm25 = '1';
                if(pm25 < 0) pm25 = '0';
            }

            //co2
            let co2 = elm.co2;
            if (co2 >= CO2_VBAD_START) {
                co2 = '4';
            } else if (co2 >= CO2_BAD_START) {
                co2 = '3';
            } else if (co2 >= CO2_NORMAL_START) {
                co2 = '2';
            } else {
                co2 = '1';
                if(co2 < 0) co2 = '0';
            }

            //voc
            let voc = elm.voc;
            if(VOC_INDICATION == 0) {
                //always good. it means voc is don't care for total status
                if(voc < 0) voc = '0';
                else voc = '1';
            } else {

                if (voc >= TVOC_VBAD_START) {
                    voc = '4';
                } else if (voc >= TVOC_BAD_START) {
                    voc = '3';
                } else if (voc >= TVOC_NORMAL_START) {
                    voc = '2';
                } else {
                    voc = '1';
                    if(voc < 0) voc = '0';
                }
            }

            if(pm10 === '4' || pm25 === '4' || co2 === '4' || voc === '4'){
                ret.status = '4';
                ret.statusText = '매우 나쁨';

                ret.causepm10 = (pm10 === '4') ? true : false;
                ret.causepm25 = (pm25 === '4') ? true : false;
                ret.causeco2 = (co2 === '4') ? true : false;
                ret.causevoc = (voc === '4') ? true : false;
            }else if(pm10 === '3' || pm25 === '3' || co2 === '3' || voc === '3'){
                ret.status = '3';
                ret.statusText = '나쁨';

                ret.causepm10 = (pm10 === '3') ? true : false;
                ret.causepm25 = (pm25 === '3') ? true : false;
                ret.causeco2 = (co2 === '3') ? true : false;
                ret.causevoc = (voc === '3') ? true : false;
            }else if(pm10 === '2' || pm25 === '2' || co2 === '2' || voc === '2'){
                ret.status = '2';
                ret.statusText = '보통';

                ret.causepm10 = (pm10 === '2') ? true : false;
                ret.causepm25 = (pm25 === '2') ? true : false;
                ret.causeco2 = (co2 === '2') ? true : false;
                ret.causevoc = (voc === '2') ? true : false;
            }else{
                if(pm10 === '0' && pm25 === '0' && co2 === '0' && voc === '0') {
                    ret.status = '0';
                    ret.statusText = '정보없음';
                } else {
                    ret.status = '1';
                    ret.statusText = '좋음';
                }

                ret.causepm10 = false;
                ret.causepm25 = false;
                ret.causeco2 = false;
                ret.causevoc = false;
            }

            return ret;
        }
    }
}

export default table;